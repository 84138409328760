<template>
    <b-modal centered
             hide-header
             hide-footer
             size="md"
             no-close-on-backdrop
             no-close-on-esc
             content-class="rounded-0"
             v-bind:visible="value"
             v-on:hide="hideModal"
             v-on:cancel="hideModal">
        <div class="text-center mb-1">
            <b-spinner class="loading-spinner"></b-spinner>
        </div>
        <h4 class="text-loading font-weight-bolder text-center mb-">
            Cleaning up<span>.</span><span>.</span><span>.</span>
        </h4>
        <h5 class="text-center font-weight-normal">
            Please do not close this window.
        </h5>
    </b-modal>
</template>

<script>
    import {
        BModal,
        BSpinner,
    } from "bootstrap-vue";

    export default {
        name: "ModalLoggingOut",
        components: {
            BModal,
            BSpinner,
        },
        props: {
            "value": {
                type: Boolean,
                default: false,
            },
        },
        data () {
            return {

            };
        },
        computed: {

        },
        methods: {
            hideModal () {
                this.$emit("input", false);
            },
        },
    }
</script>

<style lang="scss" scoped>
    .loading-spinner {
        width: 3rem;
        height: 3rem;
    }

    .text-loading {
        span {
            animation-name: blink;
            animation-duration: 1400ms;
            animation-iteration-count: infinite;
            animation-fill-mode: both;

            &:nth-child(2) {
                animation-delay: 200ms;
            }

            &:nth-child(3) {
                animation-delay: 400ms;
            }
        }

        @keyframes blink {
            0% {
                opacity: 0.2;
            }

            20% {
                opacity: 1;
            }

            100% {
                opacity: 0.2;
            }
        }
    }
</style>
