<template>
    <div class="login-wrapper d-flex justify-content-center align-items-center">
        <form class="login-form bg-light shadow-lg pt-4 px-4 pb-3"
              v-on:submit.prevent.stop="login">
            <div class="mb-5">
                <img class="logo"
                     src="../assets/logo.svg"
                     alt="logo">
            </div>

            <h1 class="h2 font-weight-bolder mb-3">
                Welcome back📣
            </h1>

            <template v-if="userStatus === 'loggingIn'">
                <div class="spinner-wrapper d-flex justify-content-center align-items-center">
                    <b-spinner variant="ssid"
                               style="width: 5rem; height: 5rem;">
                    </b-spinner>
                </div>
            </template>
            <template v-else>
                <div class="rounded mb-5">
                    <label for="inputUsername" class="sr-only">
                        Username
                    </label>
                    <b-form-input type="text"
                                  id="inputUsername"
                                  class="bg-white border-0 shadow-sm"
                                  required
                                  autofocus
                                  placeholder="👤 Username"
                                  v-bind:state="valid"
                                  v-model="username"
                                  v-on:focus="valid = valid === false ? null : valid">
                    </b-form-input>

                    <label for="inputPassword" class="sr-only">
                        Password
                    </label>
                    <b-form-input type="password"
                                  id="inputPassword"
                                  class="bg-white border-left-0 border-right-0 border-bottom-0 shadow-sm"
                                  required
                                  placeholder="🔑 Password"
                                  v-bind:state="valid"
                                  v-model="password"
                                  v-on:focus="valid = valid === false ? null : valid">
                    </b-form-input>
                    <div class="invalid-feedback"
                         v-if="valid === false">
                        Incorrect credentials.
                    </div>
                    <button class="btn btn-sm btn-link"
                            type="button"
                            v-on:click.prevent="isForgetPasswordModalOpen = true">
                        Forget password？
                    </button>
                </div>

                <button class="btn btn-dark btn-block shadow-sm"
                        type="submit"
                        v-bind:class="{ 'btn-loading': isLoading }">
                    Login
                </button>
            </template>

            <p class="mt-5 mb-0 text-muted">
                Client Land &copy; 2021 {{ new Date().getFullYear() > 2021 ? `- ${ new Date().getFullYear() }` : "" }} OblonDATA.IO Limited
            </p>

            <modal-forget-password v-model="isForgetPasswordModalOpen"></modal-forget-password>
        </form>
    </div>
</template>

<script>
import {
    BSpinner,
    BFormInput,
} from "bootstrap-vue";
import ModalForgetPassword from "../components/Login/ModalForgetPassword";

export default {
    name: "Login",
    components: {
        ModalForgetPassword,
        BSpinner,
        BFormInput,
    },
    metaInfo: {
        title: "Login",
    },
    data () {
        return {
            isForgetPasswordModalOpen: false,
            isLoading: false,
            valid: null,

            username: "",
            password: ""
        };
    },
    computed: {
        userStatus () {
            return this.$store.getters["user/status"];
        },
    },
    methods: {
        async login () {
            const { username, password, } = this;
            try {
                await this.$store.dispatch(
                    "user/login",
                    { username, password, }
                );
            } catch (e) {
                return;
            }
            this.$router.push(
                {
                    "path": this.$route.query.to ? this.$route.query.to : "/",
                    "query": Object.assign({}, this.$route.query, { "to": undefined }),
                }
            );
        },
    },
}
</script>

<style lang="scss" scoped>
.login-wrapper {
    height: 100vh;

    .login-form {
        width: 40vw;
        min-width: 330px;
        max-width: 380px;
        margin: auto;

        border-radius: 1rem;

        .logo {
            height: 120px;
            width: auto;
            border-top-left-radius: 1rem;
            border-bottom-right-radius: 0.5rem;
        }

        .spinner-wrapper {
            min-height: 181.75px;
        }

        .form-control {
            position: relative;
            box-sizing: border-box;
            height: auto;
            padding: 10px;
            font-size: 16px;

            &:focus {
                z-index: 2;
            }
        }

        input[type="text"] {
            margin-bottom: -1px;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
        }

        input[type="password"] {
            margin-bottom: 10px;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }
    }
}
</style>
