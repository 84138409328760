import { library } from "@fortawesome/fontawesome-svg-core";

import {
    faHome as faHomeSolid,
    faUsers as faUsersSolid,
    faCogs as faCogsSolid,
    faTachometerAltFastest as faTachometerAltFastestSolid,
    faSignOutAlt as faSignOutAltSolid,
    faExclamationTriangle as faExclamationTriangleSolid,
    faBadgeSheriff as faBadgeSheriffSolid,
    faTags as faTagsSolid,
    faBars as faBarsSolid,
    faPlusSquare as faPlusSquareSolid,
    faSpinner as faSpinnerSolid,
    faClipboardCheck as faClipboardCheckSolid,
    faPaperPlane as faPaperPlaneSolid,
    faSave as faSaveSolid,
    faTimes as faTimesSolid,
    faChevronLeft as faChevronLeftSolid,
    faCheckCircle as faCheckCircleSolid,
    faTimesCircle as faTimesCircleSolid,
    faPencilAlt as faPencilAltSolid,
    faTrashAlt as faTrashAltSolid,
    faMapMarkerAlt as faMapMarkerAltSolid,
    faBoxCheck as faBoxCheckSolid,
    faFileSpreadsheet as faFileSpreadsheetSolid,

    faArrowLeft as faArrowLeftSolid,

    faEnvelope as faEnvelopeSolid,
    faComment as faCommentSolid,
} from "@fortawesome/pro-solid-svg-icons";

import {
    faLongArrowRight as faLongArrowRightRegular,
    faChevronRight as faChevronRightRegular,
    faChevronLeft as faChevronLeftRegular,
    faEllipsisV as faEllipsisVRegular,

    faIdBadge as faIdBadgeRegular,
    faTag as faTagRegular,
    faMapMarkerAlt as faMapMarkerAltRegular,

    faCalendarWeek as faCalendarWeekRegular,

    faArrowRight as faArrowRightRegular,
} from "@fortawesome/pro-regular-svg-icons";

import {

} from "@fortawesome/pro-light-svg-icons";

import {
    faUsers as faUsersDuotone,
    faBadgeSheriff as faBadgeSheriffDuotone,
    faCogs as faCogsDuotone,
    faPhone as faPhoneDuotone,
    faAt as faAtDuotone,
} from "@fortawesome/pro-duotone-svg-icons";

import {
    faFacebookSquare,
    faInstagram,
    faTwitter,
    faWhatsapp,
    faTelegram,
    faWeixin,
} from "@fortawesome/free-brands-svg-icons";

library.add(
    faHomeSolid,
    faUsersSolid,
    faCogsSolid,
    faTachometerAltFastestSolid,
    faSignOutAltSolid,
    faExclamationTriangleSolid,
    faBadgeSheriffSolid,
    faBoxCheckSolid,
    faFileSpreadsheetSolid,
    faArrowLeftSolid,
    faTagsSolid,
    faBarsSolid,
    faPlusSquareSolid,
    faSpinnerSolid,
    faClipboardCheckSolid,
    faPaperPlaneSolid,
    faSaveSolid,
    faTimesSolid,
    faChevronLeftSolid,
    faCheckCircleSolid,
    faTimesCircleSolid,
    faPencilAltSolid,
    faTrashAltSolid,
    faMapMarkerAltSolid,

    faEnvelopeSolid,
    faCommentSolid,
    faFacebookSquare,
    faInstagram,
    faTwitter,
    faWhatsapp,
    faTelegram,
    faWeixin,

    faLongArrowRightRegular,
    faChevronRightRegular,
    faChevronLeftRegular,
    faEllipsisVRegular,
    faTagRegular,
    faIdBadgeRegular,
    faMapMarkerAltRegular,
    faCalendarWeekRegular,

    faArrowRightRegular,

    faUsersDuotone,
    faBadgeSheriffDuotone,
    faCogsDuotone,
    faPhoneDuotone,
    faAtDuotone,
);
