<template>
    <b-modal size="md"
             centered
             hide-header
             hide-footer
             content-class="shadow border-0"
             v-bind:visible="value"
             v-on:hide="hideModal"
             v-on:cancel="hideModal"
             v-on:close="hideModal">
        <h1 class="font-weight-bolder mb-4">
            My account
        </h1>
        <div>
            <div class="text-center mb-2">
                <b-avatar variant="dark"
                          size="5.5rem"
                          v-bind:src="avatar">
                </b-avatar>
            </div>
            <h3 class="font-weight-bold text-center mb-2">
                {{ name }}
            </h3>
            <div class="mb-4">
                <p class="content text-center mb-2">
                    <font-awesome-icon v-bind:icon="['fad', 'at']"></font-awesome-icon>
                    {{ email }}
                </p>
                <p class="content text-center mb-0">
                    <font-awesome-icon v-bind:icon="['fad', 'phone']"></font-awesome-icon>
                    {{ countryCode }} {{ phone }}
                </p>
            </div>

            <div class="d-flex">
                <button class="btn btn-warning btn-block shadow-sm mt-0 mr-2"
                        v-on:click="isModalEditAccountOpen = true">
                    Edit account
                </button>
                <button class="btn btn-danger btn-block shadow-sm mt-0"
                        v-on:click="logout">
                    Logout
                </button>
            </div>
        </div>

        <modal-edit-account v-model="isModalEditAccountOpen"></modal-edit-account>
    </b-modal>
</template>

<script>
import {
    BModal,
    BAvatar,
} from "bootstrap-vue";
import ModalEditAccount from "./ModalEditAccount";

export default {
    name: "ModalAccount",
    components: {
        ModalEditAccount,
        BModal,
        BAvatar,
    },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
            isModalEditAccountOpen: false,
        };
    },
    computed: {
        user () {
            return this.$store.getters["user/info"];
        },
        avatar () {
            return this.user?.avatar ?? "";
        },
        name () {
            return this.user?.username ?? "";
        },
        email () {
            return this.user?.email ?? "";
        },
        countryCode () {
            return this.user?.countryCode ?? "";
        },
        phone () {
            return this.user?.phone ?? "";
        },
    },
    methods: {
        hideModal () {
            this.$emit("input", false);
        },
        async logout () {
            await this.$store.dispatch("user/logout");
            this.$router.push("/login");
        },
    },
}
</script>

<style lang="scss" scoped>

</style>
