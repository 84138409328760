import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import layout from "./Layout";
import user from "./User";
import data from "./Data";

export default new Vuex.Store(
    {
        "modules": {
            layout,
            user,
            data,
        },
    }
);
