import Vue from "vue";
import VueRouter from "vue-router";

import stores from "../stores/index";

import Login from "../layout/Login";
import Default from "../layout/Default";

Vue.use(VueRouter);

const routes = [
    {
        path: "/login",
        name: "login",
        component: Login,
        beforeEnter (to, from, next) {
            if (stores.state.user.status === "loggedIn") {
                const route = {
                    "path": to.query.to ? to.query.to : "/",
                    "query": Object.assign({}, to.query, { "to": undefined }),
                };
                next(route);
                return;
            }
            next();
        },
    },
    {
        path: "/",
        component: Default,
        beforeEnter (to, from, next) {
            console.log(stores.state.user);
            if (stores.state.user.status !== "loggedIn") {
                const route = {
                    "path": "/login",
                    "query": {
                        ...to.query,
                        "to": to.path,
                    }
                };
                next(route);
                return;
            }
            next();
        },
        children: [
            {
                path: "",
                redirect: "/dashboard"
            },
            {
                path: "/dashboard",
                component: () => import("../views/Dashboard.vue"),
            },
            {
                path: "/clients",
                component: () => import("../views/Clients.vue"),
                children: [
                    {
                        path: "",
                        component: () => import("../components/Clients/ClientsPlaceholder.vue"),
                    },
                    {
                        path: ":clientId",
                        component: () => import("../components/Clients/ClientDetails.vue"),
                        props: true,
                    },
                ],
            },
            {
                path: "/pbl",
                component: () => import("../views/PBL.vue"),

            },
            {
                path: "/settings",
                component: () => import("../views/Settings.vue"),
            },
        ],
    },
    {
        path: "*",
        redirect: {
            name: "login"
        }
    }
]

const router = new VueRouter({
    "mode": "history",
    "base": process.env.BASE_URL,
    routes
});

export default router;
