<template>
    <main class="main-panel-wrapper p-1"
          v-bind:class="{ 'shrunk': isSideBarOpen }">
        <div class="main-panel h-100 shadow-lg">
            <router-view></router-view>
        </div>
    </main>
</template>

<script>
export default {
    name: "MainPanel",
    data () {
        return {

        };
    },
    computed: {
        isSideBarOpen () {
            return this.$store.state.layout.isSideBarOpen;
        },
    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.main-panel-wrapper {
    height: 100vh;
    margin-left: 0;
    transition: margin 500ms ease;

    @include media-breakpoint-down(lg) {
        margin-left: -105px;

        &.shrunk {
            margin-left: 0;
        }
    }

    @include media-breakpoint-down(md) {
        margin-left: -100px;

        &.shrunk {
            margin-left: 0;
        }
    }



    & > .main-panel {
        background-color: rgba(255, 255, 255, 1);
        border-radius: 25px;

        overflow-y: auto;
        overflow-x: hidden;
    }
}
</style>
