import {
    DefaultDataStoreName,
} from "../config";

import { v4 as uuidv4 } from "uuid";
import localforage from "localforage";

const Data = {
    namespaced: true,
    state: {
        store: null,
        currentStore: "",
        stores: [],

        clients: [],
        needs: [],
        categories: [],

        pointSchemes: [],
        badges: [],
    },
    getters: {
        currentStore ({ currentStore }) {
            return currentStore;
        },
        stores ({ stores }) {
            return stores;
        },

        clients ({ clients }) {
            return clients;
        },
        getClientById ({ clients }) {
            return (clientId) => clients.find(
                ({id}) => id === clientId
            );
        },
        getClientsByCategory ({ clients }) {
            return (categoryId) => clients.filter(
                ({ category }) => category?.id === categoryId
            );
        },
        getClientsByPointScheme ({ clients }) {
            return (schemeId) => clients.filter(
                ({ pointScheme }) => pointScheme?.id === schemeId
            );
        },
        getClientsByBadge ({ clients }) {
            return (badgeId) => clients.filter(
                ({ badges }) => badges.findIndex(({ id }) => id === badgeId) > -1
            );
        },
        needs ({ needs }) {
            return needs;
        },
        categories ({ categories }) {
            return categories;
        },
        pointSchemes ({ pointSchemes }) {
            return pointSchemes;
        },
        badges ({ badges }) {
            return badges;
        },
    },
    mutations: {
        setStore (state, store) {
            state.store = store;
        },
        setCurrentStore (state, name) {
            state.currentStore = name;
        },
        setStores (state, stores) {
            state.stores = stores;
        },

        setClients (state, clients) {
            state.clients = clients;
        },
        addClient (state, client) {
            state.clients.push({ "id": uuidv4(), ...client });
            state.store.setItem("clients", state.clients);
        },
        editClient (state, client) {
            state.clients.splice(
                state.clients.findIndex(({ id }) => id === client.id),
                1,
                client
            );
            state.store.setItem("clients", state.clients);
        },
        deleteClient (state, clientId) {
            state.clients.splice(
                state.clients.findIndex(({ id }) => id === clientId),
                1,
            );
            state.store.setItem("clients", state.clients);
        },

        setNeeds (state, needs) {
            state.needs = needs;
        },
        addNeed (state, need) {
            state.needs.push({ "id": uuidv4(), ...need });
            state.store.setItem("needs", state.needs);
        },
        editNeed (state, need) {
            state.needs.splice(
                state.needs.findIndex(({ id }) => id === need.id),
                1,
                need
            );
            state.store.setItem("needs", state.needs);
        },
        deleteNeed (state, needId) {
            state.needs.splice(
                state.needs.findIndex(({ id }) => id === needId),
                1,
            );
            state.store.setItem("needs", state.needs);
        },

        setCategories (state, categories) {
            state.categories = categories;
        },
        addCategory (state, category) {
            state.categories.push({ "id": uuidv4(), ...category });
            state.store.setItem("categories", state.categories);
        },
        editCategory (state, category) {
            state.categories.splice(
                state.categories.findIndex(({ id }) => id === category.id),
                1,
                category
            );
            state.store.setItem("categories", state.categories);
        },
        deleteCategory (state, categoryId) {
            state.categories.splice(
                state.categories.findIndex(({ id }) => id === categoryId),
                1,
            );
            state.store.setItem("categories", state.categories);
        },

        setPointSchemes (state, pointSchemes) {
            state.pointSchemes = pointSchemes;
        },
        addPointScheme (state, scheme) {
            state.pointSchemes.push({ "id": uuidv4(), ...scheme });
            state.store.setItem("pointSchemes", state.pointSchemes);
        },
        editPointScheme (state, scheme) {
            state.pointSchemes.splice(
                state.pointSchemes.findIndex(({ id }) => id === scheme.id),
                1,
                scheme
            );
            state.store.setItem("pointSchemes", state.pointSchemes);
        },
        deletePointScheme (state, schemeId) {
            state.pointSchemes.splice(
                state.pointSchemes.findIndex(({ id }) => id === schemeId),
                1,
            );
            state.store.setItem("pointSchemes", state.pointSchemes);
        },

        setBadges (state, badges) {
            state.badges = badges;
        },
        addBadge (state, badge) {
            state.badges.push({ "id": uuidv4(), ...badge });
            state.store.setItem("badges", state.badges);
        },
        editBadge (state, badge) {
            state.badges.splice(
                state.badges.findIndex(({ id }) => id === badge.id),
                1,
                badge
            );
            state.store.setItem("badges", state.badges);
        },
        deleteBadge (state, badgeId) {
            state.badges.splice(
                state.badges.findIndex(({ id }) => id === badgeId),
                1,
            );
            state.store.setItem("badges", state.badges);
        },
    },
    actions: {
        async open (
            { commit },
            storeName = DefaultDataStoreName,
        ) {
            const directory = localforage.createInstance({
                "name": "crm-store-directory"
            });
            const stores = await directory.getItem("list") ?? [];
            if (stores.findIndex(({ name }) => name === storeName) === -1) {
                stores.push({ "name": storeName });
            }
            await directory.setItem("list", stores);
            commit("setStores", stores);
            commit("setCurrentStore", storeName);

            const store = localforage.createInstance({
                "name": storeName
            });
            commit("setStore", store);

            commit("setClients", await store.getItem("clients") ?? []);
            commit("setNeeds", await store.getItem("needs") ?? []);
            commit("setCategories", await store.getItem("categories") ?? []);
            commit("setPointSchemes", await store.getItem("pointSchemes") ?? []);
            commit("setBadges", await store.getItem("badges") ?? []);
        },
    },
};

export default Data;
