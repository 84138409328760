<template>
    <div id="app"
         class="bg-main">
        <router-view></router-view>
        <b-toast no-auto-hide
                 solid
                 v-model="isUpdateAvailable">
            <template v-slot:toast-title>
                <strong>
                    Update available
                </strong>
            </template>
            <div class="d-flex justify-content-between">
                <div class="d-flex align-items-center">
                    Click to update👉
                </div>
                <div class="d-flex align-items-center">
                    <button class="btn btn-sm btn-primary border px-3"
                            v-on:click="updateApp">
                        Refresh
                    </button>
                </div>
            </div>
        </b-toast>

        <b-toast no-auto-hide
                 solid
                 v-model="isOffline">
            <template v-slot:toast-title>
                <strong>
                    Offline
                </strong>
            </template>
            The site is now offline. Information may become stale.
        </b-toast>
    </div>
</template>

<script>
import {
    BToast,
} from "bootstrap-vue";

export default {
    components: {
        BToast,
    },
    metaInfo: {
        titleTemplate: "%s | Client Land",
    },
    data () {
        return {

        };
    },
    computed: {
        userStatus () {
            return this.$store.getters["user/status"];
        },
        isOffline () {
            return !this.$store.getters["layout/isOnline"];
        },
        isUpdateAvailable () {
            return this.$store.getters["layout/isUpdateAvailable"];
        },
        serviceWorker () {
            return this.$store.getters["layout/serviceWorker"];
        },
    },
    methods: {
        updateApp () {
            if (navigator.serviceWorker) {
                if (this.serviceWorker) {
                    let refreshing = false;
                    navigator.serviceWorker.addEventListener(
                        "controllerchange",
                        () => {
                            if (refreshing) {
                                return;
                            }
                            window.location.reload();
                            refreshing = true;
                        }
                    );

                    this.serviceWorker.postMessage({
                        "type": "SKIP_WAITING"
                    });
                }
            }
        },
    },
    async created () {
        await this.$store.dispatch("user/init");
        await this.$store.dispatch("data/open");

        if (this.userStatus === "loggedIn") {
            const route = {
                "path": this.$route.query.to ? this.$route.query.to : "/",
                "query": Object.assign({}, this.$route.query, { "to": undefined }),
            };
            this.$router.push(route);
        }
    },
    mounted () {
        window.addEventListener(
            "online",
            () => {
                this.$store.commit("layout/online");
            }
        );

        window.addEventListener(
            "offline",
            () => {
                this.$store.commit("layout/offline");
            }
        );
    },
}
</script>

<style lang="scss">
$theme-colors: (
    "main": #597da2,
);
@import "~bootstrap/scss/bootstrap";
@import "~bootstrap-vue/src/index";

html,
body {
    background-color: rgba(245, 245, 245, 1);

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    font-family:
        -apple-system,
        BlinkMacSystemFont,
        Segoe UI,
        Roboto,
        Helvetica Neue,
        Arial,
        "Microsoft JhengHei",
        sans-serif,
        Apple Color Emoji,
        Segoe UI Emoji,
        Segoe UI Symbol,
        Noto Color Emoji;

    overflow-x: hidden;

    @include media-breakpoint-down(md) {
        overflow-y: hidden;
    }
}

.modal-rounded {
    border-radius: 1rem;
}

.rounded-lg {
    border-radius: 1rem !important;
}

.btn {
    &.btn-loading {
        position: relative;
        pointer-events: none;
        color: transparent !important;

        &:after {
            position: absolute;
            left: calc(50% - (1em / 2));
            top: calc(50% - (1em / 2));

            display: block;
            height: 1em;
            width: 1em;

            border: 2px solid;
            border-color: rgba(185, 185, 185, 1);
            border-radius: 290486px;
            border-right-color: transparent;
            border-top-color: transparent;

            content: "";

            animation: spinAround 500ms infinite linear;
        }
    }
}
</style>
