<template>
    <aside class="position-relative side-bar d-flex flex-column justify-content-center py-1 pl-1">
        <router-link class="btn btn-main border-0 text-nowrap mb-3"
                     active-class="active"
                     to="/dashboard">
            <span class="d-block h4 text-center">
                 <font-awesome-icon icon="tachometer-alt-fastest"></font-awesome-icon>
            </span>
            Dashboard
        </router-link>

        <router-link class="btn btn-main border-0 text-nowrap mb-3"
                     active-class="active"
                     to="/clients">
            <span class="d-block h4 text-center">
                 <font-awesome-icon icon="users"></font-awesome-icon>
            </span>
            Clients
        </router-link>

        <router-link class="btn btn-main border-0 text-nowrap mb-3"
                     active-class="active"
                     to="/pbl">
            <span class="d-block h4 text-center">
                 <font-awesome-icon icon="badge-sheriff"></font-awesome-icon>
            </span>
            PBL
        </router-link>

        <router-link class="btn btn-main border-0 text-nowrap mb-3"
                     active-class="active"
                     to="/settings">
            <span class="d-block h4 text-center">
                 <font-awesome-icon icon="cogs"></font-awesome-icon>
            </span>
            Settings
        </router-link>

        <button class="btn btn-main border-0 text-nowrap mb-3"
                v-on:click="logout">
            <span class="d-block h4 text-center">
                <font-awesome-icon icon="sign-out-alt"></font-awesome-icon>
            </span>
            Logout
        </button>

        <button-account class="position-absolute btn-account"></button-account>

        <modal-logging-out v-model="isCleaningUp"></modal-logging-out>
    </aside>
</template>

<script>
import ModalLoggingOut from "../modals/ModalLoggingOut";
import ButtonAccount from "./SideBar/ButtonAccount";
export default {
    name: "SideBar",
    components: {
        ButtonAccount,
        ModalLoggingOut

    },
    data () {
        return {
            isCleaningUp: false,
        };
    },
    computed: {
        userRole () {
            return this.$store.state.user.user.role;
        },
    },
    methods: {
        async logout () {
            this.isCleaningUp = true;

            window.onbeforeunload = () => "Please wait till the cleanup process finish. ";
            await this.$store.dispatch("record/clear");
            window.onbeforeunload = undefined;

            this.$store.dispatch("user/logout");

            this.isCleaningUp = false;

            this.$router.push("/login");
        },
    },
    mounted () {
        document
            .getElementById("app")
            .addEventListener(
                "click",
                (evt) => {
                    if (
                        !evt.target.matches(".side-bar") &&
                        !evt.target.matches(".side-bar *") &&
                        !evt.target.matches(".btn-toggle-side-bar") &&
                        !evt.target.matches(".btn-toggle-side-bar *")
                    ) {
                        this.$store.commit("layout/closeSideBar");
                    }
                }
            );
    }
}
</script>

<style lang="scss" scoped>
.side-bar {
    height: 100vh;

    .btn {
        width: 100px;
        transition:
            transform 300ms ease-in-out,
            box-shadow 300ms ease-in-out,
            font-weight 150ms ease-in-out;

        &.active {
            transform: translate(-10px);
            box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.08);
            font-weight: bolder;
        }
    }
}

.btn-account {
    bottom: 0;
}
</style>
