<template>
    <div class="row no-gutters flex-nowrap">
        <side-bar></side-bar>
        <main-panel class="col"></main-panel>
    </div>
</template>

<script>
import SideBar from "../components/layout/SideBar";
import MainPanel from "./MainPanel";
export default {
    name: "Default",
    components: {
        MainPanel,
        SideBar
    },
    data () {
        return {

        };
    },
    computed: {

    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>

</style>
