import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./stores";

Vue.config.productionTip = false;

import smoothscroll from "smoothscroll-polyfill";
smoothscroll.polyfill();

import VueMeta from "vue-meta";
Vue.use(
    VueMeta,
    {
        "refreshOnceOnNavigation": true
    }
);

import "./init/font-awesome";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
Vue.component("font-awesome-icon", FontAwesomeIcon);

/*import {
    SentryDSN,
} from "./config";
import * as Sentry from "@sentry/browser";
import { Vue as VueIntegration, } from "@sentry/integrations";
import { Integrations, } from "@sentry/tracing";
Sentry.init(
    {
        "dsn": SentryDSN,
        "integrations": [
            new VueIntegration(
                {
                    Vue,
                    "tracing": true,
                }
            ),
            new Integrations.BrowserTracing(),
        ],
        "tracesSampleRate": 1.0,
    }
);*/

new Vue(
    {
        router,
        store,
        "render": h => h(App)
    }
).$mount("#app");
