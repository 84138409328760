export const BaseUrl = "";
export const APIUrl = "";
export const APIVersion = "v1.0";

export const TokenName = "dbiz.crm";

export const SentryDSN = "";

export const DefaultDataStoreName = "default-store";

export const DefaultLogin = {
    username: "tester@test.com",
    password: "2MAVFpukjc5JGYMCpjWwM6BwWrPCyN",
};

export const DefaultUser = {
    avatar: "",
    company: "ACME Limited",
    username: "Tester Fong",
    email: "tester.fong@acme.com",
    countryCode: "+852",
    phone: "23456789",
    colleagues: [],
    signatures: [],
}
