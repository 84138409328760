import localforage from "localforage";

import {
    DefaultLogin,
    DefaultUser,
} from "../config";

export const UserStore = {
    namespaced: true,
    state: {
        status: "loggedOut", // loggedOut, loggedIn
        store: null,
        user: null,
    },
    getters: {
        status ({ status }) {
            return status;
        },
        info ({ user }) {
            return user;
        },
    },
    mutations: {
        setStore (state, store) {
            state.store = store;
        },
        setUser (state, user) {
            state.user = user;
        },
        setStatus (state, status) {
            state.status = status;
        },
    },
    actions: {
        async init (
            { commit },
        ) {
            const store = localforage.createInstance({
                "name": "user"
            });
            commit("setStore", store);
            let user = await store.getItem("user");
            if (!user) {
                user = DefaultUser;
            }
            store.setItem("user", user);
            commit("setUser", user);

            const lastLoggedIn = await localforage.getItem("lastLoggedIn");
            if (
                lastLoggedIn &&
                lastLoggedIn > Date.now() - 7 * 24 * 60 * 60 * 1000
            ) {
                commit("setStatus", "loggedIn");
            }

            return user;
        },
        async update (
            { commit, state, },
            update
        ) {
            const { store } = state;
            let user = await store.getItem("user");
            user = Object.assign({}, user, update);
            store.setItem("user", user);
            commit("setUser", user);
            return user;
        },
        async login (
            { commit, },
            { username, password }
        ) {
            if (
                username !== DefaultLogin.username ||
                password !== DefaultLogin.password
            ) {
                throw "incorrect credentials";
            }
            await localforage.setItem("lastLoggedIn", Date.now());
            commit("setStatus", "loggedIn");
        },
        async logout (
            { commit, },
        ) {
            await localforage.removeItem("lastLoggedIn");
            commit("setStatus", "loggedOut");
        }
    },
};

export default UserStore;
